import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
import DreamConstructor from './DreamConstructor';
import Auth from './Auth';
import UserContext from './UserContext';
import ProtectedRoute from './ProtectedRoute';  // Import ProtectedRoute
import './App.css';
import { auth } from './firebase';  // Import necessary Firebase functions
import { onAuthStateChanged } from 'firebase/auth';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        if (currentUser) {
            setUser(currentUser);  // Update the user in context
        } else {
            setUser(null);
        }
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, []);

  return (
    <div className="App">
      <UserContext.Provider value={{ user, setUser }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Auth />} />
            {/* Use ProtectedRoute for routes that need protection */}
            <Route 
              path="/memorykeeper" 
              element={
                <ProtectedRoute>
                  <DreamConstructor />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/memorykeeper/:searchTerm" 
              element={
                <ProtectedRoute>
                  <DreamConstructor />
                </ProtectedRoute>
              } 
            />
            {/* Add other routes as needed */}
          </Routes>
        </BrowserRouter>
      </UserContext.Provider>
    </div>
  );
}

export default App;
